<template>
  <div class='card'>
      <TabView>
        <TabPanel :header="t['454']">
          <h5 class='font-bold hr-line-bottom mb-4'>{{ t['440'] }}</h5>
          <div class='p-fluid grid'>
            <div class="field col-12 md:col-4">
            <span class="p-float-label">
                <InputText id="idInput" type="text" :disabled='true' v-model='UIDValue'/>
                <label for="idInput" class='font-light'>{{ t['430'] }}</label>
            </span>
            </div>
            <div class="field col-12 md:col-2">
            <span class="p-float-label">
                <Dropdown id="statusInput" v-model="statusValue" :options="statusValueList" optionLabel="value" :disabled='true'/>
                <label for="statusInput" class='font-light'>{{ t['433'] }}</label>
            </span>
            </div>
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                  <!--       TODO: Add locale for individual user for data display           -->
                  <Calendar id="expirationDateInput" v-model="expirationDateValue" :showIcon="true" :minDate='new Date()' :dateFormat="t['dateFormat']" :showButtonBar='true'/>
                  <label for="expirationDateInput" class='font-bold'>{{ t['438'] }}</label>
              </span>
            </div>
            <div class="field col-12 md:col-4">
            <span class="p-float-label">
                <InputText id="firstNameInput" type="text" v-model="firstNameValue" v-bind:class="{ 'p-error' : invalidFirstNameMessage }"/>
                <label for="firstNameInput" class='font-bold'>{{ t['442'] }}</label>
            </span>
              <small id="firstNameHelp" class="p-error" v-if='invalidFirstNameMessage'>{{ invalidFirstNameMessage }}</small>
            </div>
            <div class="field col-12 md:col-4">
            <span class="p-float-label">
                <InputText id="middleNameInput" type="text" v-model="middleNameValue" />
                <label for="middleNameInput" class='font-bold'>{{ t['443'] }}</label>
            </span>
            </div>
            <div class="field col-12 md:col-4">
            <span class="p-float-label">
                <InputText id="lastNameInput" type="text" v-model="lastNameValue" v-bind:class="{ 'p-error' : invalidLastNameMessage }"/>
                <label for="lastNameInput" class='font-bold'>{{ t['444'] }}</label>
            </span>
              <small id="lastNameHelp" class="p-error" v-if='invalidLastNameMessage'>{{ invalidLastNameMessage }}</small>
            </div>
            <div class="field col-12 md:col-4">
            <span class="p-float-label">
                <InputText id="emailInput" type="email" v-model="emailValue" v-bind:class="{ 'p-error' : invalidEmailMessage }"/>
                <label for="emailInput" class='font-bold'>{{ t['432'] }}</label>
            </span>
              <small id="emailHelp" class="p-error" v-if='invalidEmailMessage'>{{ invalidEmailMessage }}</small>
            </div>
          </div>
          <h5 class='font-bold hr-line-bottom mb-0'>{{ t['445'] }}</h5>
          <RolePicker @roleValueChange='getRoleValue' @defaultRoleChange='getDefaultRoleValue'></RolePicker>
          <small id="roleHelp" class="p-error" v-if='invalidRoleMessage'>{{ invalidRoleMessage }}</small>
          <h5 class='font-bold hr-line-bottom mb-4'>{{ t['446'] }}</h5>
          <div class="field col-12 hr-line-bottom">
            <span class="p-float-label">
                <Textarea id='commentInput' v-model='commentValue' :autoResize='true' style='width: 100%'></Textarea>
                <label for="commentInput" class='font-bold'>{{ t['447'] }}</label>
            </span>
          </div>
        </TabPanel>
        <TabPanel :header="t['455']">
          <div class='p-fluid grid hr-line-bottom'>
            <h5 class='font-bold hr-line-bottom col-12 mb-12'>{{ t['435'] }}</h5>
            <div class="field col-12 md:col-3">
              <span class="p-float-label">
                  <Dropdown id="languageInput" v-model="languageValue" :options="languageValueList" optionLabel="value" optionValue='key' v-bind:class="{ 'p-error' : invalidLanguageMessage }"/>
                  <label for="languageInput" class='font-bold'>{{ t['435'] }}</label>
              </span>
              <small id="languageHelp" class="p-error" v-if='invalidLanguageMessage'>{{ invalidLanguageMessage }}</small>
            </div>
            <h5 class='font-bold hr-line-bottom col-12 mb-12'>{{ t['459'] }}</h5>
            <div class="field col-12 md:col-3">
            <span class="p-float-label">
                <Dropdown id="dateFormatInput" v-model="dateFormatValue" :options="dateFormatValueList" optionLabel="value" optionValue='key' v-bind:class="{ 'p-error' : invalidDateFormatMessage }"/>
                <label for="dateFormatInput" class='font-bold'>{{ t['456'] }}</label>
            </span>
              <small id="dateFormatHelp" class="p-error" v-if='invalidDateFormatMessage'>{{ invalidDateFormatMessage }}</small>
            </div>
            <div class="field col-12 md:col-3">
            <span class="p-float-label">
                <Dropdown id="timeFormatInput" v-model="timeFormatValue" :options="timeFormatValueList" optionLabel="value" optionValue='key' v-bind:class="{ 'p-error' : invalidTimeFormatMessage }"/>
                <label for="timeFormatInput" class='font-bold'>{{ t['457'] }}</label>
            </span>
              <small id="timeFormatHelp" class="p-error" v-if='invalidTimeFormatMessage'>{{ invalidTimeFormatMessage }}</small>
            </div>
            <div class="field col-12 md:col-3">
            <span class="p-float-label">
                <Dropdown id="firstDayOfTheWeekInput" v-model="firstDayOfWeekValue" :options="firstDayOfWeekValueList" optionLabel="value" optionValue='key' v-bind:class="{ 'p-error' : invalidFirstDayOfWeekMessage }"/>
                <label for="firstDayOfTheWeekInput" class='font-bold'>{{ t['458'] }}</label>
            </span>
              <small id="firstDayOfTheWeekHelp" class="p-error" v-if='invalidFirstDayOfWeekMessage'>{{ invalidFirstDayOfWeekMessage }}</small>
            </div>
          </div>
        </TabPanel>
      </TabView>
      <div>
        <Button class='p-button-primary mr-2' @click='addUser'>{{ t['87'] }}</Button>
        <Button class='p-button-outlined' @click="$router.push('/users')">{{ t['86'] }}</Button>
      </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { usePrimeVue } from 'primevue/config';
import axios from 'axios';

export default {
  name: 'AddUser',
  setup() {
    const store = useStore()
    const primeVue = usePrimeVue()

    return {
      token: computed(() => store.state.bearerToken),
      t: primeVue.config.locale,
    }
  },
  data() {
    return {
        UIDValue: null,
        statusValue: null,
        statusValueList: null,
        firstNameValue: null,
        middleNameValue: null,
        lastNameValue: null,
        emailValue:null,
        expirationDateValue:null,
        languageValue: null,
        languageValueList: null,
        roleValue: null,
        defaultRoleValue: null,
        commentValue: null,
        dateFormatValue: null,
        dateFormatValueList: null,
        timeFormatValue: null,
        timeFormatValueList: null,
        firstDayOfWeekValue: null,
        firstDayOfWeekValueList: null,
        // invalidMessages
        invalidFirstNameMessage: false,
        invalidLastNameMessage: false,
        invalidEmailMessage: false,
        invalidLanguageMessage: false,
        invalidRoleMessage: false,
        invalidDateFormatMessage: false,
        invalidTimeFormatMessage: false,
        invalidFirstDayOfWeekMessage: false,
        validInputs: true,
    }
  },
  watch: {

  },
  created() {

  },
  mounted() {
    this.getStatusValueList()
    this.getLanguagesValueList()
    this.getDateFormatValueList()
    this.getTimeFormatValueList()
    this.getFirstDayOfTheWeekValueList()
  },
  methods: {
      getStatusValueList() {
          axios
              .get( '/app/webObjects/dropdown/USTATUSES/KEY-VALUE/false/get', {
                  headers: {
                      Authorization: this.token
                  }})
              .then( response => {
                  if(response.status === 200 && response.data.rmsList[0].retCode === 0){
                      this.statusValueList = response.data.retObj.dropDownList;
                  }else {
                      this.$toast.add({severity:'error', summary: 'Error', detail:response.data.rmsList[0].retMsg, life: 3000});
                  }
              })
              .catch(error => {
                  console.log("There was an error loading user status list!");
                  console.log(error);
              })
      },
      getLanguagesValueList() {
          axios
              .get( '/app/webObjects/dropdown/ACTIVE_LANGUAGES/KEY-VALUE/false/get', {
                  headers: {
                      Authorization: this.token
                  }})
              .then( response => {
                  if(response.status === 200 && response.data.rmsList[0].retCode === 0){
                      this.languageValueList = response.data.retObj.dropDownList;
                  }else {
                      this.$toast.add({severity:'error', summary: 'Error', detail:response.data.rmsList[0].retMsg, life: 3000});
                  }
              })
              .catch(error => {
                  console.log("There was an error loading language list!");
                  console.log(error);
              })
      },
      getDateFormatValueList() {
        axios
            .get( '/app/webObjects/dropdown/DATE_FORMATS/VALUE/false/get', {
              headers: {
                Authorization: this.token
              }})
            .then( response => {
              if(response.status === 200 && response.data.rmsList[0].retCode === 0){
                this.dateFormatValueList = response.data.retObj.dropDownList;
              }else {
                this.$toast.add({severity:'error', summary: 'Error', detail:response.data.rmsList[0].retMsg, life: 3000});
              }
            })
            .catch(error => {
              console.log("There was an error loading date format list!");
              console.log(error);
            })
      },
      getTimeFormatValueList() {
        axios
            .get( '/app/webObjects/dropdown/TIME_FORMATS/VALUE/false/get', {
              headers: {
                Authorization: this.token
              }})
            .then( response => {
              if(response.status === 200 && response.data.rmsList[0].retCode === 0){
                this.timeFormatValueList = response.data.retObj.dropDownList;
              }else {
                this.$toast.add({severity:'error', summary: 'Error', detail:response.data.rmsList[0].retMsg, life: 3000});
              }
            })
            .catch(error => {
              console.log("There was an error loading time format list!");
              console.log(error);
            })
      },
      getFirstDayOfTheWeekValueList() {
        axios
            .get( '/app/webObjects/dropdown/WEEKDAYS/VALUE/false/get', {
              headers: {
                Authorization: this.token
              }})
            .then( response => {
              if(response.status === 200 && response.data.rmsList[0].retCode === 0){
                this.firstDayOfWeekValueList = response.data.retObj.dropDownList;
              }else {
                this.$toast.add({severity:'error', summary: 'Error', detail:response.data.rmsList[0].retMsg, life: 3000});
              }
            })
            .catch(error => {
              console.log("There was an error loading first day of the week list!");
              console.log(error);
            })
      },
      getRoleValue(data) {
        this.roleValue = data
      },
      getDefaultRoleValue(data) {
        this.defaultRoleValue = data
      },
      addUser() {
        this.verifyInputValues()
          if (this.validInputs) {
              let expirationDate = null
              if(this.expirationDateValue) {
                  let date = new Date(this.expirationDateValue)
                  let userTimeZoneOffset = date.getTimezoneOffset() * 60000
                  this.expirationDateValue = new Date(date.getTime() - userTimeZoneOffset)
                  expirationDate = this.expirationDateValue.getTime()
              }

              axios
                  .post('/administration/user/create', {
                      'fName': this.firstNameValue,
                      'mName': this.middleNameValue,
                      'lName': this.lastNameValue,
                      'email': this.emailValue,
                      'lang': this.languageValue,
                      'exp': expirationDate,
                      'roles': this.roleValue,
                      'defRole': this.defaultRoleValue,
                      'comment': this.commentValue,
                      'dateFormat': this.dateFormatValue,
                      'timeFormat': this.timeFormatValue,
                      'firstDayOfWeek': this.firstDayOfWeekValue,
                  }, {
                      headers: {
                          Authorization: this.token
                      }
                  })
                  .then(res => {
                      if( res.status === 200 && res.data.rmsList[0].retCode === 405) {
                          setTimeout(() => {
                              this.$toast.add({severity:'success', summary: 'Success!', detail:res.data.rmsList[0].retMsg, life: 3000})
                          }, 1000)
                          this.$router.replace('/users')
                      }else {
                          this.$toast.add({severity:'error', summary: 'Error', detail:res.data.rmsList[0].retMsg, life: 3000})
                      }
                  })
                  .catch(error  => {
                      console.log(error);
                  })
          }
      },
      verifyInputValues() {
        this.validInputs = true
        if(!this.firstNameValue) {
          this.invalidFirstNameMessage = this.t['448']
            this.validInputs = false
        } else {
          this.invalidFirstNameMessage = false
        }
        if(!this.lastNameValue) {
          this.invalidLastNameMessage = this.t['449']
            this.validInputs = false
        } else {
          this.invalidLastNameMessage = false
        }
        if(!this.emailValue) {
          this.invalidEmailMessage = this.t['450']
            this.validInputs = false
        } else {
            const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            if(!regex.test(this.emailValue.toLowerCase())) {
                this.invalidEmailMessage = this.t['451']
            } else {
                this.invalidEmailMessage = false
            }
        }
        if(!this.languageValue) {
          this.invalidLanguageMessage = this.t['452']
            this.validInputs = false
        } else {
          this.invalidLanguageMessage = false
        }
        if(!this.defaultRoleValue) {
          this.invalidRoleMessage = this.t['453']
            this.validInputs = false
        } else {
          this.invalidRoleMessage = false
        }
        if(!this.dateFormatValue) {
          // TODO: change translations
          this.invalidDateFormatMessage = this.t['452']
          this.validInputs = false
        } else {
          this.invalidDateFormatMessage = false
        }
        if(!this.timeFormatValue) {
          // TODO: change translations
          this.invalidTimeFormatMessage = this.t['452']
          this.validInputs = false
        } else {
          this.invalidTimeFormatMessage = false
        }
        if(!this.firstDayOfWeekValue) {
          // TODO: change translations
          this.invalidFirstDayOfWeekMessage = this.t['452']
          this.validInputs = false
        } else {
          this.invalidFirstDayOfWeekMessage = false
        }
      }
  }
};
</script>

<style scoped lang='scss'>

.hr-line-dashed-bottom {
  border-bottom: 1px dashed lightgray;
}

.hr-line-bottom {
  border-bottom: 1px solid lightgray;
}
</style>